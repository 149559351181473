import React from "react"
import { Link,graphql } from 'gatsby'

import Layout from "../../components/layout"
import Next from "../../components/next"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>Enhanced Split Ticketing Support</h1>

      <p>Split Ticketing as been regularly seen in the news since early 2018. If you are not familiar with it, it is a
      perfectly valid and relatively simply way for your customers to pay a lower price for their journey.</p>

      <p>In a nutshell, it can sometimes be cheaper, to buy a ticket from A to B, and a second ticket from B to C than
      it is to buy a direct ticket from A to C. Provided your train from A to C stops at B, then the ticket is valid.</p>

      <p>Many regular commuters have known about fare anomalies that lead to these savings for many years. What's new 
      is that a few radical new <Link to="/smartix/journey-planner/">journey planners</Link> can calculate such savings 
      automatically.</p>

      <p><Link to="/smartix/" className="printLink">smartix</Link> has been designed to work hand in hand with 
      such <Link to="/smartix/journey-planner/">journey planners</Link> to provide an enhanced service when purchasing
      these tickets compared with existing split ticket retail sites.</p>

      <Next submenu={data.allRailapisubmenuJson.edges}/>

    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page